import { DiagramBase, DiagramParent, IDiagramBase, IDiagramDataItem, IDiagramParent } from './base.api';
import { ISolution, Solution, CategoryQuestion } from './solution.api';
import { ILevel2, ITechnology, Level2, Technology } from './e2e-taxonomy.api';
import { IOrganization, Organization } from './common';
import { IUseCaseLibrary, IUseCaseSimulation } from './simulation.api';
import { TreeNode } from 'primeng/api';

/**
 * Represents general data for a specific entity.
 * @interface
 */
export interface IGeneralData {
	cycle_time: string;
	type_cycle: string;
	fte: string;
	effort: string;
	effort_cycle: string;
	taxonomy: TreeNode[];
}

/**
 * Represents a work plan item.
 *
 * @interface
 */
export interface IWorkPlanItem {
	actives: boolean[];
	steps: { id: string; title: string; actives: boolean[] }[];
	activeStep: number;
	text: string;
	min: string;
	max: string;
}

/**
 * Interface representing work plan data.
 *
 * @interface
 */
export interface IWorkPlanData {
	description: string;
	min: string;
	max: string;
	unit: string;
	items: IWorkPlanItem[];
}

export interface IBenefit {
	label: string;
	description: string;
	currentBaseline: number;
	targetState: number;
	timeFrame: string;
	fav: boolean;
	expr: string;
	default: boolean;
	calculation: string;
	commentary: string;
	measure: string;
	symbol: string;
	descriptionForClient: string;
	descriptionCalculation: string;
	required: boolean;
}

export interface IUseCaseOrder {
	heatmap: number;
	benefits: number;
	my_use_case: number;
	hubble: number;
}

export interface IBenefitData {
	revenue_growth: IBenefit[];
	working_capital: IBenefit[];
	customer_experience: IBenefit[];
	process_productivity: IBenefit[];
	employee_productivity: IBenefit[];
	cost_savings: IBenefit[];
}

/**
 * Represents the values associated with an AI (Artificial Intelligence) model.
 *
 * @interface
 */
interface IAiValues {
	score: number;
	effort_score: number;
	impact_score: number;
	technologies_score: number;
	solutions_score: number;
	description: string;
}

/**
 * Represents the data of an answer form.
 *
 * @interface IAnswerFormData
 */
export interface IAnswerFormData {
	value: string;
	note: string;
}

/**
 * Represents the form data for a category.
 *
 * @interface ICategoryFormData
 */
export interface ICategoryFormData {
	value: string;
	effort: string;
	impact: string;
	answers: { [key: string]: IAnswerFormData };
}

export interface INexusData {
	[key: string]: any;

	form: { [key: string]: string };

	status: {
		functional_requirements: '0' | '1' | '2' | '3';
		non_functional_req: '0' | '1' | '2' | '3';
		inputs_and_data_sources: '0' | '1' | '2' | '3';
		output_and_reports: '0' | '1' | '2' | '3';
		designDiagram: '0' | '1' | '2' | '3';
		genAICheckList: '0' | '1' | '2' | '3';
	};

	functional_requirements: {
		name: string;
		description: string;
		detailed_task: [
			{ task_name: string[]; task_description: string[]; AI_Enabler: string[]; AI_Technology: string[] },
		];
	}[];
	non_functional_req: {
		name: string;
		performance: string;
		security: string;
		usability: string;
		maintainability: string;
		KPIs: [{ name: string; value: string }];
	};
	inputs_and_data_sources: {
		data_requirements: string;
		AI_system: string;
		provide_by: string;
		inputs_sources: {
			DT_RowId: string;
			name: string;
			source: string;
			description: string;
			format: string;
			type: string;
			freq_updates: string;
			tools: string;
			files: string[];
		}[];
	};
	output_and_reports: {
		general_description: string;
		AI_system: string;
		outputs_data: {
			DT_RowId: string;
			name: string;
			user_persona: string;
			description: string;
			format: string;
			type: string;
			freq_updates: string;
			tools: string;
			files: string[];
		}[];
	};
	designDiagram: {};
	genAICheckList: {
		title: string;
		description: string;
		questions: [
			{
				title: string;
				question: string;
				options: string[];
				selected: string;
				commentary: string;
			},
		];
	};
}

interface ICalculatedData {
	impact: string;
	matrixReadiness: {
		value: number;
		color: string;
		label_value: string;
		class_value: string;
	};
	matrixValue: {
		value: number;
		color: string;
		label_value: string;
		class_value: string;
	};
	matrixBenefits: {
		value: number;
		color: string;
		label_value: string;
		class_value: string;
	};
	topBenefits: {
		label: string;
		label_value: string;
		class_value: string;
	}[];
}

/**
 * Represents a use case in the system.
 * @interface IUseCase
 * @extends IDiagramParent<IDiagram>
 */
export interface IUseCase extends IDiagramParent<IDiagram> {
	solution: ISolution;
	solutionId: string;

	technologies: ITechnology[];
	technologiesIds: string[];

	levels2: ILevel2[];
	levels2Ids: string[];

	generalData: IGeneralData;
	workPlanData: IWorkPlanData;
	formData: { [key: string]: ICategoryFormData };

	organization: IOrganization;
	organizationId: string;

	aiValues: IAiValues;

	hubble: boolean;
	benefits: boolean;

	nexus: INexusData;

	calculatedData: ICalculatedData;
	benefitsValues: IBenefitData;
	benefitsOrder: IUseCaseOrder;

	simulationData: IUseCaseSimulation;
	libraryData: IUseCaseLibrary;
}

/**
 * Represents a UseCase object.
 * @extends DiagramParent<Diagram>
 * @implements IUseCase
 */
export class UseCase extends DiagramParent<Diagram> implements IUseCase {
	solution: Solution;
	technologies: Technology[];
	levels2: Level2[];

	organization: Organization;
	categories: CategoryQuestion[];
	calculatedData: ICalculatedData;
	benefitsData: any;

	constructor(
		public solutionId: string,
		public technologiesIds: string[],
		public levels2Ids: string[],
		public generalData: IGeneralData,
		public workPlanData: IWorkPlanData,
		public formData: { [key: string]: ICategoryFormData },
		public benefitsValues: IBenefitData,
		public organizationId: string,
		public aiValues: IAiValues,
		public benefitsOrder: IUseCaseOrder,
		public hubble: boolean,
		public benefits: boolean,
		public nexus: INexusData,
		public simulationData: IUseCaseSimulation,
		public libraryData: IUseCaseLibrary,
		name: string,
		description: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(name, description, created_at, updated_at, id);
		Object.defineProperties(this, {
			solution: { value: undefined, enumerable: false, writable: true },
			technologies: { value: [], enumerable: false, writable: true },
			levels2: { value: [], enumerable: false, writable: true },
			values: { value: [], enumerable: false, writable: true },
			_totalValue: { value: 0, enumerable: false, writable: true },
			totalValueChartData: { value: undefined, enumerable: false, writable: true },
			totalValueChartOptions: { value: undefined, enumerable: false, writable: true },
			organization: { value: undefined, enumerable: false, writable: true },
			categories: { value: undefined, enumerable: false, writable: true },
			calculatedData: { value: undefined, enumerable: false, writable: true },
			benefitsData: { value: undefined, enumerable: false, writable: true },
		});
	}

	/**
	 * Retrieves an array of ICategoryFormData objects based on specific category names.
	 *
	 * @returns {ICategoryFormData[]} An array of ICategoryFormData objects that match the specified category names.
	 */
	get values(): ICategoryFormData[] {
		return this.categories
			.filter((c) => c.name === 'Governance' || c.name === 'Data' || c.name === 'Talent')
			.map((c) => this.formData[c.id as any]);
	}

	/**
	 * Retrieve all values from the form data based on the categories.
	 *
	 * @returns {ICategoryFormData[]} An array of category form data values.
	 */
	get allValues(): ICategoryFormData[] {
		return this.categories.map((c) => this.formData[c.id as any]);
	}

	/**
	 * Converts the value of a given object to a number.
	 *
	 * @param {ICategoryFormData | undefined} value - The value to convert to a number.
	 *
	 * @return {number} - The converted number. If `value` is undefined or falsy, returns 0.
	 */
	numValue(value: ICategoryFormData | undefined): number {
		if (value) {
			return value.value && value.value !== 'N/A' ? parseFloat(parseFloat(value.value).toFixed(0)) : 0;
		} else {
			return 0;
		}
	}

	getLabelValue(value: ICategoryFormData | undefined): '---' | 'Ready' | 'Remediate' | 'Stop' {
		const numValue = this.numValue(value);
		if (numValue) {
			if (numValue > 75) {
				return 'Ready';
			} else if (numValue > 55) {
				return 'Remediate';
			} else {
				return 'Stop';
			}
		} else {
			return '---';
		}
	}

	/**
	 * Calculates the background CSS class value based on the provided value.
	 * @param {ICategoryFormData | undefined} value - The value to calculate the background CSS class for.
	 * @return {string} The calculated background CSS class value.
	 */
	backgroundValue(value: ICategoryFormData | undefined): string {
		const css = ['background-heatmap'];
		const numValue = this.numValue(value);
		if (numValue) {
			if (numValue > 75) {
				css.push('background-high');
			} else if (numValue > 55) {
				css.push('background-medium');
			} else {
				css.push('background-low');
			}
		} else {
			css.push('background-none');
		}
		return css.join(' ');
	}

	/**
	 * Retrieves the value of a specific category question based on its ID.
	 *
	 * @param {string} categoryQuestionId - The ID of the category question.
	 * @return {ICategoryFormData} - The value of the category question.
	 */
	getValue(categoryQuestionId: string): ICategoryFormData {
		return this.formData[categoryQuestionId];
	}

	/**
	 * Retrieves the value associated with a given category name.
	 *
	 * @param {string} name - The name of the category. Must be one of the following: 'Governance', 'Data', or 'Talent'.
	 * @return {ICategoryFormData | undefined} - The value associated with the category, or undefined if the category is not found.
	 */
	getValueByCategoryName(name: 'Governance' | 'Data' | 'Talent'): ICategoryFormData | undefined {
		const category = this.categories.find((c) => c.name === name);
		if (category && category.id) {
			return this.getValue(category.id);
		}
		return undefined;
	}

	// Benefits

	getBenefitEmpty(
		label: string,
		descriptionForClient: string = '',
		descriptionCalculation: string,
		expr: string = '',
		measure: string = '',
		symbol = '',
		defaultField: boolean,
	): IBenefit {
		return {
			label,
			description: '',
			currentBaseline: 0,
			targetState: 0,
			timeFrame: '',
			fav: true,
			expr,
			default: defaultField,
			commentary: '',
			calculation: '',
			measure: measure,
			symbol,
			descriptionForClient,
			descriptionCalculation,
			required: false,
		};
	}

	// calculateTotalDifference(revenueGrowth: any[], costSavingsData: any[]): string {

	hasBenefitsData() {
		return (
			this.hasNonZeroValues(this?.benefitsValues?.revenue_growth) ||
			this.hasNonZeroValues(this?.benefitsValues?.working_capital) ||
			this.hasNonZeroValues(this?.benefitsValues?.customer_experience) ||
			this.hasNonZeroValues(this?.benefitsValues?.process_productivity) ||
			this.hasNonZeroValues(this?.benefitsValues?.employee_productivity) ||
			this.hasNonZeroValues(this?.benefitsValues?.cost_savings)
		);
	}

	getCategoriesBenefits(): (
		| 'revenue_growth'
		| 'working_capital'
		| 'customer_experience'
		| 'process_productivity'
		| 'employee_productivity'
		| 'cost_savings'
	)[] {
		return [
			'revenue_growth',
			'working_capital',
			'customer_experience',
			'process_productivity',
			'employee_productivity',
			'cost_savings',
		];
	}

	getCategoryNameBenefit(
		key:
			| 'revenue_growth'
			| 'working_capital'
			| 'customer_experience'
			| 'process_productivity'
			| 'employee_productivity'
			| 'cost_savings',
	): string {
		return {
			revenue_growth: 'Revenue',
			working_capital: 'Working Capital',
			customer_experience: 'Customer Exp',
			process_productivity: 'Process Productivity',
			employee_productivity: 'Employee Capacity',
			cost_savings: 'Cost Savings',
		}[key];
	}

	hasBenefits(
		key:
			| 'revenue_growth'
			| 'working_capital'
			| 'customer_experience'
			| 'process_productivity'
			| 'employee_productivity'
			| 'cost_savings',
	): boolean {
		return this.hasNonZeroValues(this.benefitsValues[key]);
	}

	getBenefits(
		key:
			| 'revenue_growth'
			| 'working_capital'
			| 'customer_experience'
			| 'process_productivity'
			| 'employee_productivity'
			| 'cost_savings',
	): IBenefit[] {
		return this.benefitsValues[key] || [];
	}

	calculateSavingsDifference(costSavingsData: IBenefit[]): number {
		let totalCurrentBaseline = 0;
		let totalTargetState = 0;

		costSavingsData?.forEach((benefit) => {
			if (benefit.label === 'Cost Reduction') {
				totalCurrentBaseline += benefit.currentBaseline;
				totalTargetState += benefit.targetState;
			}
		});

		if (totalCurrentBaseline !== 0 && totalTargetState !== 0) {
			return Math.abs(totalTargetState - totalCurrentBaseline);
		}

		return 0;
	}

	calculateWorkingCapitalDifference(workingCapital: IBenefit[], revenueGrowthBenefits: IBenefit[]): number {
		let dso = 0;
		let dpo = 0;
		let dio = 0;

		workingCapital?.forEach((benefit) => {
			if (benefit.label === 'Days Sales Outstanding (DSO)') {
				dso += benefit.targetState - benefit.currentBaseline;
			} else if (benefit.label === 'Days Payable Outstanding (DPO)') {
				dpo += benefit.targetState - benefit.currentBaseline;
			} else if (benefit.label === 'Days Inventory Outstanding (DIO)') {
				dio += benefit.targetState - benefit.currentBaseline;
			}
		});
		const revenueGrowth = this.calculateRevenueDifference(revenueGrowthBenefits);
		const workingCapitalDifference = (dso + dio - dpo) * (revenueGrowth / 365);

		return workingCapitalDifference;
	}

	calculateCustomerDifference(customer_experience: IBenefit[]): number {
		let totalDifference = 0;

		customer_experience?.forEach((benefit) => {
			if (benefit.label === 'Customer Satisfaction') {
				totalDifference += Math.abs(benefit.targetState - benefit.currentBaseline);
			}
		});

		return parseFloat(totalDifference.toFixed(2));
	}

	calculateEmployeeCapacity(employee_productivity: IBenefit[], cost_savings: IBenefit[]): { total: number, percentTotal: number } {
		let totalCapacity = 0;
		let totalCurrentBaseline = 0;
		let hasValidValues = false;

		const FTEs = cost_savings?.find((benefit) => benefit.label === 'FTEs');

		if (FTEs) {
			employee_productivity?.forEach((benefit) => {
				if (benefit.label === 'Capacity Creation (hours)' && benefit.targetState !== 0 && benefit.currentBaseline !== 0) {
					totalCapacity += (benefit.currentBaseline - benefit.targetState) * FTEs.currentBaseline;
					totalCurrentBaseline += benefit.currentBaseline;
					hasValidValues = true;
				}
			});
		}

		if (hasValidValues && totalCurrentBaseline !== 0) {
			const percentTotal = (totalCapacity / totalCurrentBaseline) * 100;
			return { total: Math.abs(totalCapacity), percentTotal: parseFloat(percentTotal.toFixed(2)) };
		}

		return { total: 0, percentTotal: 0 };
	}

	calculateRevenueDifference(revenueGrowth: IBenefit[]): number {
		const revenueBenefit = revenueGrowth?.find((benefit) => benefit.label === 'Revenue');
		if (revenueBenefit) {
			const targetState = revenueBenefit.targetState || 0;
			const currentBaseline = revenueBenefit.currentBaseline || 0;
			if (targetState === 0 && currentBaseline === 0) {
				return 0;
			}
			return targetState - currentBaseline;
		}
		return 0;
	}

	calculateTotalDifference(revenueGrowth: IBenefit[], costSavingsData: IBenefit[]): number {
		const revenueDifference = this.calculateRevenueDifference(revenueGrowth);
		const savingsDifference = this.calculateSavingsDifference(costSavingsData);

		if (!revenueDifference && !savingsDifference) {
			return 0;
		}

		return Math.abs(Math.abs(revenueDifference) + Math.abs(savingsDifference));
	}

	/* 	numberWithCommas(num:number): number {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	} */

	getBenefitValue(): number {
		// console.log(this.benefitsValues.revenue_growth, this.benefitsValues.cost_savings);
		return this.calculateTotalDifference(this.benefitsValues.revenue_growth, this.benefitsValues.cost_savings);
	}

	hasNonZeroValues(benefits: IBenefit[] | undefined): boolean {
		return benefits ? benefits.some((data) => data.targetState !== 0 || data.currentBaseline !== 0) : false;
	}

	/* formatWithSuffix(value: number): string {
		const suffixes = ['', 'K', 'M', 'B', 'T'];
		let suffixIndex = 0;
		value = Math.abs(value);

		while (value >= 1000 && suffixIndex < suffixes.length - 1) {
			value /= 1000;
			suffixIndex++;
		}

		const formattedValue =
			value % 1 === 0
				? value.toFixed(0)
				: value
						.toFixed(2)
						.replace(/\.0$/, '')
						.replace(/(\.\d)0$/, '$1');

		return `${formattedValue}${suffixes[suffixIndex]}`;
	} */

	formatWithSuffix(value: number): string {
		const suffixes = ['', 'K', 'M', 'B', 'T'];
		let suffixIndex = 0;

		// Check if the number is negative
		const isNegative = value < 0;

		// Use absolute value for formatting
		value = Math.abs(value);

		while (value >= 1000 && suffixIndex < suffixes.length - 1) {
			value /= 1000;
			suffixIndex++;
		}

		const formattedValue =
			value % 1 === 0
				? value.toFixed(0)
				: value
						.toFixed(1)
						.replace(/\.0$/, '')
						.replace(/(\.\d)0$/, '$1');

		// Add the negative sign back if the number was negative
		return `${isNegative ? '-' : ''}${formattedValue}${suffixes[suffixIndex]}`;
	}

	evaluateBenefitExpression(
		data: IBenefit,
		key:
			| 'revenue_growth'
			| 'working_capital'
			| 'customer_experience'
			| 'process_productivity'
			| 'employee_productivity'
			| 'cost_savings',
	): string {
		const category = this.getCategoryNameBenefit(key);
		try {
			const absoluteDifference = data.targetState - data.currentBaseline;
			const percentDifference = data.currentBaseline !== 0 ? ((data.targetState - data.currentBaseline) / data.currentBaseline) * 100 : 0;
			const increaseOrDecrease = percentDifference >= 0 ? 'increase' : 'decrease';

			if (data.symbol === '$') {
				return `$${this.formatWithSuffix(absoluteDifference)} in ${category} (${
					percentDifference >= 0 ? '+' : '-'
				}${
					Number.isInteger(percentDifference)
						? Math.abs(percentDifference).toFixed(0)
						: Math.abs(percentDifference).toFixed(1)
				}%)`;
			} else if (data.symbol === '%') {
				return `${percentDifference >= 0 ? '+' : '-'}${
					Number.isInteger(percentDifference)
						? Math.abs(percentDifference).toFixed(0)
						: Math.abs(percentDifference).toFixed(1)
				}% ${increaseOrDecrease} in ${data.label} to ${absoluteDifference} for ${category}`;
			} else if (data.symbol === '#') {
				return `${absoluteDifference} ${data.measure.toLowerCase()} ${
					absoluteDifference >= 0 ? 'increase' : 'decrease'
				} in ${data.label} in ${category} (${percentDifference >= 0 ? '+' : '-'}${
					Number.isInteger(percentDifference)
						? Math.abs(percentDifference).toFixed(0)
						: Math.abs(percentDifference).toFixed(1)
				}%)`;
			} else {
				return 'Invalid measure';
			}
		} catch (error) {
			console.error('Error calculating expression:', error);
			return 'Error';
		}
	}

	getBenefitItem(
		data: IBenefit,
		key:
			| 'revenue_growth'
			| 'working_capital'
			| 'customer_experience'
			| 'process_productivity'
			| 'employee_productivity'
			| 'cost_savings',
	): {
		label: string;
		label_value: string;
		class_value: string;
	} {
		const category = this.getCategoryNameBenefit(key);
		try {
			const absoluteDifference = Math.abs(data.targetState - data.currentBaseline);
			const percentDifference = (absoluteDifference / data.currentBaseline) * 100;
			// const increaseOrDecrease = percentDifference >= 0 ? 'increase' : 'decrease';

			if (data.symbol === '$') {
				return {
					label: category,
					label_value: `${ absoluteDifference >= 0 ? '' : '-'}$${this.formatWithSuffix(absoluteDifference) }`,
					class_value: 'background-high',
				};
			} else if (data.symbol === '%') {
				if (Number.isNaN(percentDifference)) {
					return {
						label: category,
						label_value: '0%',
						class_value: 'background-high',
					};
				}
				return {
					label: category,
					label_value: `${percentDifference >= 0 ? '+' : '-'}${
						Number.isInteger(percentDifference)
							? Math.abs(percentDifference).toFixed(0)
							: Math.abs(percentDifference).toFixed(2)
					}%`,
					class_value: 'background-high',
				};
			} else if (data.symbol === '#') {
				const measure =
					data.measure.toLowerCase() === 'full time equivalent' ? 'FTE' : data.measure.toLowerCase();
				return {
					label: category,
					label_value: `${absoluteDifference} ${measure}`,
					class_value: 'background-high',
				};
			} else {
				return {
					label: category,
					label_value: '---',
					class_value: 'background-none',
				};
			}
		} catch (error) {
			return {
				label: category,
				label_value: '---',
				class_value: 'background-none',
			};
		}
	}

	backgroundBenefit() {
		const css = ['background-heatmap'];
		const numValue = 100;
		if (numValue) {
			if (numValue > 75) {
				css.push('background-high');
			} else if (numValue > 55) {
				css.push('background-medium');
			} else {
				css.push('background-low');
			}
		} else {
			css.push('background-none');
		}
		return css.join(' ');
	}

	getLabelBenefit(): string {
		return UseCase.formatWithSuffix(this.getBenefitValue());
	}

	static formatWithSuffix(value: number): string {
		const suffixes = ['', 'K', 'M', 'B', 'T'];
		let suffixIndex = 0;

		// Check if the number is negative
		const isNegative = value < 0;

		// Use absolute value for formatting
		value = Math.abs(value);

		while (value >= 1000 && suffixIndex < suffixes.length - 1) {
			value /= 1000;
			suffixIndex++;
		}

		const formattedValue =
			value % 1 === 0
				? value.toFixed(0)
				: value
						.toFixed(1)
						.replace(/\.0$/, '')
						.replace(/(\.\d)0$/, '$1');

		// Add the negative sign back if the number was negative
		return `${isNegative ? '-' : ''}$${formattedValue}${suffixes[suffixIndex]}`;
	}

	static getLabelBenefit(numValue: number): string {
		if (!numValue) {
			return '---';
		}
		return (numValue >= 0 ? '' : '-') + '$' + Math.abs(numValue).toFixed(1);
	}

	getMatrixBenefit() {
		return {
			value: this.getBenefitValue(),
			color: '#b7f1ab',
			label_value: this.getLabelBenefit(),
			class_value: this.backgroundBenefit(),
		};
	}
}

/**
 * Represents a diagram with use case information and organization information.
 */
export interface IDiagram extends IDiagramBase {
	useCase: IUseCase;
	useCaseId: string;

	organization: IOrganization;
	organizationId: string;
}

/**
 * Represents a Diagram, which is a visual representation of a use case or organization.
 * Extends the DiagramBase class and implements the IDiagram interface.
 */
export class Diagram extends DiagramBase implements IDiagram {
	useCase: UseCase;
	organization: Organization;

	constructor(
		public useCaseId: string,
		public organizationId: string,
		xml: string,
		data?: { [key: string]: IDiagramDataItem },
		capture_url?: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(xml, capture_url, data, created_at, updated_at, id);
		Object.defineProperties(this, {
			useCase: { value: undefined, enumerable: false, writable: true },
			organization: { value: undefined, enumerable: false, writable: true },
		});
	}
}
