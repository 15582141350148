<div class="grid">
	<!-- Opportunities -->
	<div class="col-12 md:col-3 flex flex-column gap-2 pr-0">
		<span class="text-lg font-bold">Opportunities</span>
		<div class="card-container h-100 w-full">
			<mat-card class="large-card h-100 d-flex" title="AI Opportunities">
				<mat-card-content class="mat-card-content d-flex align-items-center h-100 w-100">
					<div class="grid w-100 h-100 align-items-center">
						<div class="col-6 text-left d-flex flex-column justify-content-center pl-3">
							<div class="text-primary pl-3" style="font-size: 18px; font-weight: 600; line-height: 1">
								AI
							</div>
							<div class="pl-3" style="font-size: 16px; font-weight: 500; line-height: 1">
								Opportunities
							</div>
						</div>
						<div class="col-6 text-right justify-content-end align-items-center">
							<span #aiOpportunitiesCountUp class="pr-3">
								{{ simulationService.counters.aiOpportunities }}
							</span>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>

		<div class="card-container h-100 w-full">
			<mat-card class="large-card h-100 d-flex" title="Gen AI Opportunities">
				<mat-card-content class="mat-card-content d-flex align-items-center h-100 w-100">
					<div class="grid w-100 h-100 align-items-center">
						<div class="col-6 text-left d-flex flex-column justify-content-center pl-3">
							<div class="text-primary pl-3" style="font-size: 18px; font-weight: 600; line-height: 1">
								Gen AI
							</div>
							<div class="pl-3" style="font-size: 16px; font-weight: 500; line-height: 1">
								Opportunities
							</div>
						</div>
						<div class="col-6 text-right justify-content-end align-items-center">
							<span #genAiOpportunitiesCountUp class="pr-3">
								{{ simulationService.counters.genAiOpportunities }}
							</span>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>

		<div class="card-container h-100 w-full">
			<mat-card class="large-card h-100 d-flex" title="Embedded Opportunities">
				<mat-card-content class="mat-card-content d-flex align-items-center h-100 w-100">
					<div class="grid w-100 h-100 align-items-center">
						<div class="col-6 text-left d-flex flex-column justify-content-center pl-3">
							<div class="text-primary pl-3" style="font-size: 18px; font-weight: 600; line-height: 1">
								Embedded
							</div>
							<div class="pl-3" style="font-size: 16px; font-weight: 500; line-height: 1">
								Opportunities
							</div>
						</div>
						<div class="col-6 text-right justify-content-end align-items-center">
							<span #embeddedOpportunitiesCountUp class="pr-3">
								{{ simulationService.counters.embeddedAI }}
							</span>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>

	<!-- Hackett Taxonomy and Use Cases by Process -->
	<div class="col-12 md:col-9 flex flex-column gap-2">
		<div class="flex justify-content-between">
			<!-- Hackett Taxonomy -->
			<div class="col-12 md:col-6 py-0">
				<div class="taxonomy-title d-flex align-items-center">
					<app-hackett-logo></app-hackett-logo>
					<span class="taxonomy-company ms-2">Hackett Digital World Class®</span>
					<span> Taxonomy</span>
				</div>
				<div class="grid text-center card-grid">
					<div class="col-4 pb-0 pr-0">
						<mat-card class="small-card" title="# of E2E">
							<mat-card-header class="text-center justify-content-center">
								<mat-card-title class="mat-card-title"># of E2E</mat-card-title>
							</mat-card-header>
							<mat-card-content class="mat-card-content">
								<span #e2eCountUp>{{ simulationService.counters.e2e }}</span>
							</mat-card-content>
						</mat-card>
					</div>
					<div class="col-4 pb-0">
						<mat-card class="small-card" title="# of Process">
							<mat-card-header class="text-center justify-content-center">
								<mat-card-title class="mat-card-title"># of Process</mat-card-title>
							</mat-card-header>
							<mat-card-content class="mat-card-content">
								<span #processCountUp>{{ simulationService.counters.process }}</span>
							</mat-card-content>
						</mat-card>
					</div>
					<div class="col-4 pb-0">
						<mat-card class="small-card" title="# of Subprocess">
							<mat-card-header class="text-center justify-content-center">
								<mat-card-title class="mat-card-title"># of Subprocess</mat-card-title>
							</mat-card-header>
							<mat-card-content class="mat-card-content">
								<span #subProcessCountUp>{{ simulationService.counters.subProcess }}</span>
							</mat-card-content>
						</mat-card>
					</div>
				</div>
			</div>

			<!-- Gen AI Use Cases -->
			<div class="col-12 md:col-2 flex justify-content-center align-items-center py-0">
				<mat-card class="large-card text-center" title="Gen AI Use Cases">
					<mat-card-content class="mat-card-content">
						<div class="text-primary pl-2" style="font-size: 18px; font-weight: 600; line-height: 1">
							Gen AI
						</div>
						<div class="pl-2" style="font-size: 16px; font-weight: 500; line-height: 1">Use Cases</div>
						<span #genAiUseCasesCountUp>{{ simulationService.counters.genAiUseCases }}</span>
					</mat-card-content>
				</mat-card>
			</div>

			<!-- Use Cases by Process -->
			<div class="col-12 md:col-4 py-0">
				<div class="taxonomy-title">Use Cases by Process</div>
				<div class="grid text-center card-grid">
					<div class="col-4 pb-0">
						<mat-card class="small-card" title="# E2E">
							<mat-card-header class="text-center justify-content-center">
								<mat-card-title class="mat-card-title"
									># <br />
									of E2E</mat-card-title
								>
							</mat-card-header>
							<mat-card-content class="mat-card-content">
								<span #e2eSourceRelCountUp>{{ simulationService.counters.e2eSourceRel }}</span>
							</mat-card-content>
						</mat-card>
					</div>
					<div class="col-4 pb-0">
						<mat-card class="small-card" title="# Process">
							<mat-card-header class="text-center justify-content-center">
								<mat-card-title class="mat-card-title"># of Process</mat-card-title>
							</mat-card-header>
							<mat-card-content class="mat-card-content">
								<span #processSourceRelCountUp>{{ simulationService.counters.processSourceRel }}</span>
							</mat-card-content>
						</mat-card>
					</div>
					<div class="col-4 pb-0 pl-0">
						<mat-card class="small-card" title="# Subprocess">
							<mat-card-header class="text-center justify-content-center">
								<mat-card-title class="mat-card-title"># Subprocess</mat-card-title>
							</mat-card-header>
							<mat-card-content class="mat-card-content">
								<span #subProcessSourceRelCountUp>{{
									simulationService.counters.subProcessSourceRel
								}}</span>
							</mat-card-content>
						</mat-card>
					</div>
				</div>
			</div>
		</div>

		<!-- Breakthrough, Transformative, Incremental -->
		<div class="flex justify-content-between">
			<div class="col-12 md:col-4 pb-0">
				<mat-card class="small-card breakthrough-card" title="Breakthrough">
					<mat-card-header class="text-center justify-content-center">
						<mat-card-title class="text-lg text-primary">Breakthrough</mat-card-title>
					</mat-card-header>
					<mat-card-content class="mat-card-content">
						<span #breakthroughCountUp>{{ simulationService.counters.breakthrough }}</span>
					</mat-card-content>
					<p class="description px-5 pb-2 text-center">
						Exponential advancements that result in new business models, revenue streams and innovation.
					</p>
				</mat-card>
			</div>
			<div class="col-12 md:col-4 pb-0">
				<mat-card class="small-card transformative-card" title="Transformative">
					<mat-card-header class="text-center justify-content-center">
						<mat-card-title class="text-lg text-primary">Transformative</mat-card-title>
					</mat-card-header>
					<mat-card-content class="mat-card-content">
						<span #transformativeCountUp>{{ simulationService.counters.transformative }}</span>
					</mat-card-content>
					<p class="description px-5 pb-2 text-center">
						Ability to drive profound organizational changes which drive operational excellence.
					</p>
				</mat-card>
			</div>
			<div class="col-12 md:col-4 pb-0">
				<mat-card class="small-card incremental-card" title="Incremental">
					<mat-card-header class="text-center justify-content-center">
						<mat-card-title class="text-lg text-primary">Incremental</mat-card-title>
					</mat-card-header>
					<mat-card-content class="mat-card-content">
						<span #incrementalCountUp>{{ simulationService.counters.incremental }}</span>
					</mat-card-content>
					<p class="description px-5 pb-2 text-center">
						Innovations that involve targeted enhancements to existing product, processes and services.
					</p>
				</mat-card>
			</div>
		</div>
	</div>
</div>
