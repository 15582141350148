import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, ConfirmEventType, MessageService, TreeNode } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { SimulationService } from '../simulation.service';
import { Simulation, UseCaseSimulation } from '../../../api/simulation.api';
import { UseCaseService } from '../../../service/use-case.service';
import { AuthService } from '../../../service/auth.service';
import type { Api, Config } from 'datatables.net';
import { v4 as uuid } from 'uuid';
import { DbService } from '../../../service/db.service';
import { firstValueFrom } from 'rxjs';
import tippy from 'tippy.js';
import { E2ETaxonomyService } from '../../../service/e2e-taxonomy.service';
import { createdAt } from '../../../service/services.data/utils.data';
import { UseCasesService } from '../use-cases.service';

@Component({
	selector: 'app-simulation-prompt-studio',
	templateUrl: './prompt-studio.component.html',
	styleUrls: ['./prompt-studio.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class PromptStudioComponent implements OnInit {
	@ViewChild('tableUseCases') tableUseCases: ElementRef<HTMLTableElement>;

	constructor(
		private http: HttpClient,
		public e2ETaxonomyService: E2ETaxonomyService,
		public simulationService: SimulationService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		public useCaseService: UseCaseService,
		public useCasesService: UseCasesService,
		public authService: AuthService,
		public dbService: DbService,
	) {}

	public sub: any;

	public simulationId: string = '';
	public organizationName: string = '';
	public organizationId: string = '';
	public simulation: Simulation | undefined = undefined;

	async ngOnInit() {
		const organizationId: string = await this.authService.getCurrentOrganizationId();

		this.simulationService.simulationObservable.subscribe({
			next: (ready) => {
				if (ready) {
					this.clearSimulation(organizationId);
				}
			},
		});

		this.simulationService.selectedSimulationObservable.subscribe({
			next: (simulation) => {
				this.loaded = false;
				this.simulation = simulation;
				console.log('this.simulation', simulation);
				this.loadSimulation().then(() => {
					this.loaded = true;
				});
			},
		});

		this.simulationService.selectedSimulationStarObservable.subscribe({
			next: (simulation) => {
				this.loaded = false;
				this.simulation = simulation;
				this.loadSimulation().then(() => {
					this.loaded = true;
				});
			},
		});

		this.simulationService.updateUseCaseValidObservable.subscribe({
			next: (id) => {
				if (this.simulation && this.simulation.use_cases.length) {
					if (this.simulation.use_cases.find((d) => d.id === id)) {
						this.simulation.use_cases = this.simulation.use_cases.map((d) => {
							if (d.id === id) {
								d.valid = !d.valid;
							}
							return d;
						});
						this.loadTable();
					}
				}
			},
		});

		if (this.simulationService.loaded) {
			await this.clearSimulation(organizationId);
		}
	}

	aiEnablersTreeSelections: TreeNode[] = [];
	businessValueDriversTreeSelections: TreeNode[] = [];
	technologyTreeSelections: TreeNode[] = [];
	scopeTreeSelections: TreeNode[] = [];
	taxonomyTreeSelections: TreeNode[] = [];

	showParameters: boolean = true;

	toggleParameters() {
		this.showParameters = !this.showParameters;
	}

	showUseCases: boolean = true;

	hasSimulation: boolean = false;

	loaded: boolean = false;

	impact: {
		breakthrough: boolean;
		transformative: boolean;
		incremental: boolean;
	} = {
		breakthrough: false,
		transformative: false,
		incremental: false,
	};

	onClose() {
		this.displayModal = false;
	}

	getText(s: string) {
		if (s) {
			const parser = new DOMParser();
			const document = parser.parseFromString(s, 'text/html');
			return document.body.innerText.replace('\n', '');
		}
		return '';
	}

	async loadSimulation() {
		this.loaded = false;
		if (this.simulation) {
			this.simulation.name = this.getText(this.simulation.name);
			this.simulation.description = this.getText(this.simulation.description);
			this.simulation.notes = this.getText(this.simulation.notes);
			if (!this.simulation?.enterpriseContext && (this.simulation as any).enterprise_context) {
				this.simulation.enterpriseContext = (this.simulation as any).enterprise_context;
			}
			if (!this.simulation?.simulationContext && (this.simulation as any).simulation_context) {
				this.simulation.simulationContext = (this.simulation as any).simulation_context;
			}

			this.aiEnablersTreeSelections = this.simulationService.aiEnablersTreeOptions.filter((t) =>
				(this.simulation?.simulationContext.aiEnablers || []).includes(t.key as string),
			);

			this.businessValueDriversTreeSelections = this.simulationService.businessValueDriversTreeOptions.filter(
				(b) => (this.simulation?.simulationContext.business_values || []).includes(b.key as string),
			);

			this.technologyTreeSelections = this.simulationService.technologyTreeOptions.filter((t) =>
				(this.simulation?.simulationContext.technologies || []).includes(t.key as string),
			);

			this.scopeTreeSelections = this.simulationService.scopeTreeOptions.filter((t) =>
				(this.simulation?.simulationContext.scope || []).includes(t.key as string),
			);

			this.taxonomyTreeSelections = this.simulationService.addProcessTaxonomyTreeSelect.filter((t) =>
				(this.simulation?.simulationContext.taxonomy || []).includes(t.key as string),
			);

			this.impact.breakthrough = !!this.simulation.simulationContext.impact.breakthrough;
			this.impact.transformative = !!this.simulation.simulationContext.impact.transformative;
			this.impact.incremental = !!this.simulation.simulationContext.impact.incremental;

			this.simulationId = this.simulation.id as any;
			this.organizationId = this.simulation.organizationId;

			const organization = await this.authService.getOrganization(this.organizationId);
			if (organization) {
				this.organizationName = organization.name;
			}

			this.simulationData = { market_research: '', use_cases: [] };

			this.useCasesSaved = true;

			this.showUseCases = false;
			this.hasSimulation = false;
			this.use_cases_saved = [];
			if (this.simulation.use_cases?.length) {
				this.showUseCases = true;
				this.hasSimulation = true;
				this.use_cases_saved = this.simulation.use_cases.map((d) => d.id as string);
			}
			this.use_cases_deleted = [];
		}
		this.loaded = true;

		this.dtSelection = 0;

		this.loadTable();
	}

	saveSimulation() {
		if (this.simulation) {
			//console.log(this.simulation);
			if (!this.simulation.name) {
				this.messageService.add({
					severity: 'warn',
					summary: 'No name',
					detail: 'Provide a name for the Simulation',
				});
			} else if (!this.simulation.description) {
				this.messageService.add({
					severity: 'warn',
					summary: 'No description',
					detail: 'Provide a description for the Simulation',
				});
			} else {
				this.confirmationService.confirm({
					header: 'Save simulation?',
					message: 'Saving the simulation will save all the use cases.',
					icon: 'pi pi-save',
					accept: () => {
						this._save();
					},
				});
			}
		}
	}

	useCasesSaved: boolean = false;

	async existSimulation() {
		if (this.simulation) {
			const res = await firstValueFrom(
				this.http.get('@api/simulation/simulation/' + this.simulation.id + '/'),
			).catch(() => undefined);
			return !!(res as any)?.id;
		}
		return false;
	}

	async _saveSimulation(existSimulation: boolean | undefined = undefined) {
		if (this.simulation) {
			this.simulation.enterpriseContext = {
				industry: this.simulationService.industryTreeSelections.map((d) => d.key as string),
				revenue: this.simulationService.revenueTreeSelections.label as string,
				information: this.simulationService.additionalInformationTreeSelections.map((d) => d.key as string),
				employee: this.simulationService.employeesTreeSelections.label as string,
				country: this.simulationService.countriesTreeSelections.key as string,
				topProducts: this.simulationService.topProductsTreeSelections.map((d) => d as string),
				topServices: this.simulationService.topServicesTreeSelections.map((d) => d as string),
				description: this.simulationService.descriptionSelection || '',
				data_landscape: this.simulationService.data_landscape || [],
			};
			this.simulation.simulationContext = {
				aiEnablers: this.aiEnablersTreeSelections.map((d) => d.key as string),
				technologies: this.technologyTreeSelections.map((d) => d.key as string),
				business_values: this.businessValueDriversTreeSelections.map((d) => d.key as string),
				impact: {
					...this.simulation.simulationContext.impact,
				},
				taxonomy: this.taxonomyTreeSelections.map((d) => d.key as string),
				scope: this.scopeTreeSelections.map((d) => d.key as string),
			};

			if (this.simulation.enterpriseContext && !this.simulation.enterpriseContext.country) {
				this.simulation.enterpriseContext.country = this.simulationService.countriesTreeSelections
					.label as string;
			}
			const obj: any = { ...this.simulation };
			obj.organization_id = this.simulation.organizationId;
			if (this.simulation.taxonomyId) {
				obj.taxonomy_id = this.simulation.taxonomyId;
			}
			if (this.simulation.taxonomyRel) {
				obj.taxonomy_rel = this.simulation.taxonomyRel;
			}
			obj.enterprise_context = this.simulation.enterpriseContext;
			obj.simulation_context = this.simulation.simulationContext;

			if (existSimulation === undefined) {
				existSimulation = await this.existSimulation();
			}

			if (!existSimulation) {
				await firstValueFrom(this.http.post('@api/simulation/simulation/', obj));
			} else {
				await firstValueFrom(this.http.patch('@api/simulation/simulation/' + this.simulation.id + '/', obj));
			}

			this.dbService.canHooks = false;
			await this.dbService.data_simulation.put(obj);
			this.dbService.canHooks = true;
			console.log('save sim');
		}
	}

	loadingSave: boolean = false;

	async _save() {
		if (this.simulation) {
			this.loadingSave = true;
			await this._saveSimulation();

			if (!this.useCasesSaved) {
				const useCasesBase = this.simulation.use_cases
					.filter((d) => !this.use_cases_saved.includes(d.id as string))
					.map((d) => {
						return {
							...d,
							createdAt: undefined,
							updatedAt: undefined,
							deletedAt: undefined,
							__typename: undefined,
						} as any;
					});

				const useCasesList: UseCaseSimulation[][] = [];

				for (let i = 0; i < useCasesBase.length; i += 25) {
					useCasesList.push(useCasesBase.slice(i, i + 25));
				}

				let use_cases_deleted = this.use_cases_deleted;

				const promises: Promise<any>[] = [];

				this.dbService.canHooks = false;

				for (const useCases of useCasesList) {
					promises.push(
						firstValueFrom(
							this.http.post('@api/simulation/use_case_bulk/', {
								created: useCases,
								deleted: use_cases_deleted,
							}),
						),
					);
					use_cases_deleted = [];
				}

				await Promise.all(promises).catch(() => {
					this.loadingSave = false;
					this.dbService.canHooks = true;

					this.messageService.add({
						severity: 'warn',
						summary: 'Error',
						detail: 'Error saving Use Cases',
					});
				});

				this.dbService.canHooks = true;

				if (this.loadingSave) {
					for (const u of this.simulation.use_cases) {
						this.dbService.canHooks = false;
						await this.simulationService.saveUseCase(u);
						this.use_cases_saved.push(u.id as string);
						this.dbService.canHooks = true;
					}

					this.useCasesSaved = true;

					this.simulationService.updateSimulationsSubject.next(uuid());
				}
			}

			if (this.loadingSave) {
				this.loadingSave = false;

				if (!this.hasRunSimulation) {
					this.messageService.add({
						severity: 'success',
						summary: 'Confirmed',
						detail: 'Simulation saved',
					});
				}
			}
		}
	}

	hasRunSimulation: boolean = false;

	runSimulation() {
		if (this.simulation) {
			if (!this.simulation.name) {
				this.messageService.add({
					severity: 'warn',
					summary: 'No name',
					detail: 'Provide a name for the Simulation',
				});
			} else if (!this.simulation.description) {
				this.messageService.add({
					severity: 'warn',
					summary: 'No description',
					detail: 'Provide a description for the Simulation',
				});
			} else if (!this.scopeTreeSelections.map((d) => d.key as string).length) {
				this.messageService.add({
					severity: 'warn',
					summary: 'No scope',
					detail: 'Provide scope for the Simulation',
				});
			} else if (!this.impact.breakthrough && !this.impact.incremental && !this.impact.transformative) {
				this.messageService.add({
					severity: 'warn',
					summary: 'No Impact Category',
					detail: 'Provide impact category for the Simulation',
				});
			} else {
				if (!this.simulation.use_cases?.length) {
					this.confirmationService.confirm({
						header: 'Run simulation?',
						message: 'This will generate use cases',
						icon: 'pi pi-sparkles',
						accept: () => {
							this._runSimulation(true).then(() => {});
						},
					});
				} else {
					this.confirmationService.confirm({
						header: 'Run simulation?',
						message: 'This will generate new use cases',
						icon: 'pi pi-sparkles',
						acceptLabel: 'Get more',
						accept: () => {
							this._runSimulation().then(() => {});
						},
						rejectLabel: 'Start again',
						reject: (type: ConfirmEventType) => {
							switch (type) {
								case ConfirmEventType.REJECT:
									this._runSimulation(true).then(() => {});
									break;
								case ConfirmEventType.CANCEL:
									break;
							}
						},
					});
				}
			}
		}
	}

	simulationData: { market_research: string; use_cases: any[] } = { market_research: '', use_cases: [] };
	use_cases_deleted: string[] = [];
	use_cases_saved: string[] = [];

	async _runSimulation(force?: boolean) {
		const promptInputs = await this._getInput(force);
		if (this.simulation && promptInputs) {
			this.hasRunSimulation = true;

			await this._save();

			if (force && this.simulation.use_cases.length) {
				this.use_cases_deleted.push(...this.simulation.use_cases.map((u) => u.id as string));
			}

			console.log(promptInputs);

			this.http
				.post<{ market_research: string; use_cases: any[] }>(
					environment.url + '/api/ai/simulation/v6/',
					JSON.stringify(promptInputs),
				)
				.subscribe({
					error: (err) => {
						console.log(err);
						this.hasRunSimulation = false;
					},
					next: (data) => {
						console.log(data);

						if (!force) {
							const us = [];
							for (const u of this.simulation?.use_cases || []) {
								if (!data.use_cases.find((d) => d.id === u.id)) {
									us.push(u);
								} else {
									data.use_cases.map((d) => {
										if (d.id === u.id) {
											return u;
										}
										return d;
									});
								}
							}
							data.use_cases = [...us, ...data.use_cases];
						}

						this.simulationData = data;

						this._loadUseCases(data.use_cases);
					},
					complete: () => {
						this.hasSimulation = true;
						this.hasRunSimulation = false;
					},
				});
		}
	}

	async _getInput(force?: boolean) {
		if (this.simulation) {
			let inputs = {
				name: this.simulation.name || 'Use Case Name',
				description: this.simulation.description || 'Use Case Description',
				// additionalNotes: this.simulation.notes || 'Additional Notes',
				scope: this.scopeTreeSelections.map((d) => d.key as string),
				search: this.simulation.search,
				generate: this.simulation.generate,
				enterpriseContext: {
					companyName: this.organizationName,
					industry: this.simulationService.industryTreeSelections.map((d) => d.key as string),
					// country: this.simulationService.countriesTreeSelections.label || 'None',
					description: this.simulationService.descriptionSelection as string,
					// topProducts: this.simulationService.topProductsTreeSelections.map((d) => d.key as string),
					// topServices: this.simulationService.topServicesTreeSelections.map((d) => d.key as string),
					data_landscape: this.simulationService.data_landscape.map((item) => {
						return {
							[item.name]: {
								applicable: item.applicable,
								embeddAI: item.embeddedAI,
								dataSource: item.dataSource,
								tech_vendor: item.tech_vendor,
								description: item.description,
							},
						};
					}),
					revenue: this.simulationService.revenueTreeSelections.label || '1M USD',
					information: this.simulationService.additionalInformationTreeSelections.map((d) => d.label).length
						? this.simulationService.additionalInformationTreeSelections.map((d) => d.label)
						: ['Annual Revenue'],
					employee: this.simulationService.employeesTreeSelections.label || '>10000',
				},
				usesCasesContext: {
					aiEnablers: this.aiEnablersTreeSelections.map((d) => d.key).length
						? this.aiEnablersTreeSelections.map((d) => d.key)
						: ['409bde18-6f5a-4cb8-8e20-c853296b0a4f'],
					technologies: this.technologyTreeSelections.map((d) => d.label).length
						? this.technologyTreeSelections.map((d) => d.label)
						: ['Machine Learning', 'Oracle'],
					benefits: this.businessValueDriversTreeSelections.map((d) => d.key).length
						? this.businessValueDriversTreeSelections.map((d) => d.key)
						: ['revenue_growth'],
					impact: {
						breakthrough: this.impact.breakthrough ? 5 : 0,
						incremental: this.impact.incremental ? 5 : 0,
						transformative: this.impact.transformative ? 5 : 0,
					},
					taxonomy: this.taxonomyTreeSelections.map((d) => d.key as string),
				},
			};

			let promptInputs: any = {};

			if (force) {
				this.simulationData = { market_research: '', use_cases: [] };
			}

			if (!this.simulationData.market_research) {
				promptInputs = inputs;
			} else {
				await this._plainUseCases();
				promptInputs = { ...this.simulationData, inputs, generate_more: true };
			}

			return promptInputs;
		}

		return undefined;
	}

	_loadUseCases(data: any[]) {
		this.showUseCases = true;

		const useCases: UseCaseSimulation[] = [];

		this.useCasesSaved = false;

		//const sortedData = data.sort((a, b) => b.similarity_rank - a.similarity_rank);

		console.log(data);

		const sortedData = data.sort((a, b) => b.impact_assessment - a.impact_assessment);

		for (const u of sortedData) {
			if (u instanceof UseCaseSimulation) {
				useCases.push(u);
			} else {
				const useCase = new UseCaseSimulation(
					u.name,
					u.description,
					u.keyFeatures,
					{
						aiEnablers: u.aiEnablers,
						impact: [u.impact_assessment],
						business_values: [],
						tools: [],
					},
					{
						valuesDriversScores: {
							revenue_growth:
								u['valuesDriversScores.revenue_growth'] || u?.valuesDriversScores?.revenue_growth || 0,
							customer_experience:
								u['valuesDriversScores.customer_experience'] ||
								u?.valuesDriversScores?.customer_experience ||
								0,
							process_productivity:
								u['valuesDriversScores.process_productivity'] ||
								u?.valuesDriversScores?.process_productivity ||
								0,
							employee_productivity:
								u['valuesDriversScores.employee_productivity'] ||
								u?.valuesDriversScores?.employee_productivity ||
								0,
							cost_savings:
								u['valuesDriversScores.cost_savings'] || u?.valuesDriversScores?.cost_savings || 0,
						},
						llm_explanation: u.llm_explanation,
						potential_challenges: u.potential_challenges,
						process: u.process,
						subprocess: u?.subprocess,
						data_requirements: u.data_requirements,
						aiOpportunities: u.aiOpportunities,
					},
					false,
					u['type'] === 'search' ? '2' : u['type'] || '1',
					'',
					this.simulationId,
					this.organizationId,
				);
				useCases.push(useCase);
			}
		}

		if (this.simulation) {
			this.simulation.use_cases = useCases;
		}

		this.loadTable();
	}

	async _plainUseCases() {
		const useCases: any[] = [];

		for (const u of this.simulationData.use_cases || []) {
			if (u instanceof UseCaseSimulation) {
				const useCase = {
					impact_assessment: u.context.impact[0],
					description: u.description,
					name: u.name,
					ai_enablers: (await this.e2ETaxonomyService.getTechnologies({ ids: u.context.aiEnablers })).map(
						(d) => d.name,
					),
					valuesDriversScores: u.data.valuesDriversScores,
					data_requirements: u.data.data_requirements,
					potential_challenges: u.data.potential_challenges,
					llm_explanation: u.data.llm_explanation,
					type: u.type === '1' ? 'generated' : 'search',
					aiEnablers: u.context.aiEnablers,
				};
				useCases.push(useCase);
			} else {
				u.type = u.type === '1' ? 'generated' : 'search';
				useCases.push(u);
			}
		}

		this.simulationData.use_cases = useCases;
	}

	async clearSimulation(organizationId?: string) {
		this.loaded = false;
		if (!organizationId) {
			organizationId = await this.authService.getCurrentOrganizationId();
		}
		if (organizationId && this.simulationService.enterpriseContext) {
			this.simulationId = '';
			this.simulation = new Simulation(
				'',
				'',
				'',
				true,
				true,
				this.simulationService.enterpriseContext.enterpriseContext,
				this.simulationService.defaultSimulationContext,
				organizationId,
			);
			this.simulation.simulationContext.aiEnablers = this.simulationService.aiEnablersTreeOptions.map(
				(d) => d.key as string,
			);
			this.simulation.simulationContext.business_values =
				this.simulationService.businessValueDriversTreeOptions.map((d) => d.key as string);
			this.simulation.simulationContext.scope = this.simulationService.scopeTreeOptions.map(
				(d) => d.key as string,
			);
			await this.loadSimulation();
		}
		this.loaded = true;
	}

	loadTable() {
		const renderBox = (data: any, type: any, row: any) => {
			let label = '';
			let className = '';
			if (data === 0) {
				label = '---';
				className = 'background-none';
			} else if (data < 4) {
				label = 'Low';
				className = 'background-low';
			} else if (data >= 4 && data <= 7) {
				label = 'Medium';
				className = 'background-medium';
			} else {
				label = 'High';
				className = 'background-high';
			}
			return `<p class="${className}"> ${label} </p>`;
		};

		this.dtSelection = 0;
		const orderToApply = this.currentOrder.length ? this.currentOrder : [];
		this.renderTable(
			() => this.tableUseCases,
			() => ({
				order: orderToApply,
				columns: [
					{
						title: 'Use Case',
						className: 'bold-column',
						data: 'name',
					},
					{
						title: 'Revenue Growth',
						data: 'revenue_growth',
						render: renderBox,
					},
					{
						title: 'Customer Experience',
						data: 'customer_experience',
						render: renderBox,
					},
					{
						title: 'Process Productivity',
						data: 'process_productivity',
						render: renderBox,
					},
					{
						title: 'Employee Productivity',
						data: 'employee_productivity',
						render: renderBox,
					},
					{
						title: 'Cost Savings',
						data: 'cost_savings',
						render: renderBox,
					},
					{
						title: 'Impact',
						data: 'impact_assessment',
					},
					{
						title: 'Actions',
						className: 'text-center',
						render: (data: any, type: any, row: any) => {
							return `
								<div class="flex justify-content-center align-items-center">
									<button class="p-ripple p-element p-button-link p-button p-component p-button-icon-only open-button" type="button">
										<span aria-hidden="true" class="pi pi-window-maximize p-button-icon ng-star-inserted"></span>
										<span class="p-ink"></span>
									</button>
									<button class="p-ripple p-element p-button-link p-button p-component p-button-icon-only delete-button" type="button">
										<span aria-hidden="true" class="pi pi-trash p-button-icon ng-star-inserted"></span>
										<span class="p-ink"></span>
									</button>
									<i class="pi ${row.valid ? 'pi-star-fill' : 'pi-star'} star-icon ${
								row.type === '2' ? 'text-primary' : 'text-green-300'
							}" data-id="${row.id}" style="cursor: pointer; font-size: 1.5rem; margin-left: 10px;"></i>
								</div>
							`;
						},
					},
				],
				select: true,
				paging: false,
				info: false,
			}),
			() => {
				if (this.simulation) {
					return this.simulation.use_cases.map((d: UseCaseSimulation) => {
						return {
							id: d.id as string,
							name: d.name,
							description: d.description,
							valid: d.valid,
							type: d.type,
							revenue_growth: d.data?.valuesDriversScores?.revenue_growth || 0,
							customer_experience: d.data?.valuesDriversScores?.customer_experience || 0,
							process_productivity: d.data?.valuesDriversScores?.process_productivity || 0,
							employee_productivity: d.data?.valuesDriversScores?.employee_productivity || 0,
							impact_assessment: Array.isArray(d.context?.impact) ? d.context.impact.join(',') : '',
							cost_savings: d.data?.valuesDriversScores?.cost_savings || 0,
						};
					});
				} else {
					return [];
				}
			},
			(data: { id: string }[]) => {
				if (this.simulation) {
					const ids = data.map((d) => d.id);
					this.simulation.use_cases = this.simulation.use_cases.filter((d) => ids.includes(d.id as string));
				}
			},
		);
		this.restoreOrder();
	}

	public currentOrder: any[] = [];

	storeCurrentOrder() {
		if (this.dTable) {
			this.currentOrder = this.dTable.order();
		}
	}

	restoreOrder() {
		if (this.dTable && this.currentOrder.length) {
			this.dTable.order(this.currentOrder).draw(false);
		}
	}

	renderTable(
		element: () => ElementRef<HTMLTableElement>,
		getOptions: () => Config,
		getData: () => {
			id: string;
		}[],
		updateData: (
			data: {
				id: string;
			}[],
		) => void,
	) {
		setTimeout(() => {
			const data = getData();
			if (data && data.length) {
				const el = element();
				if (el && el.nativeElement) {
					this._renderTable(el.nativeElement, getOptions, getData, updateData);
				} else {
					this.renderTable(element, getOptions, getData, updateData);
				}
			}
		});
	}

	dTable: Api | undefined = undefined;
	displayModal: boolean = false;
	dtSelection: number = 0;

	currentUseCase: UseCaseSimulation | undefined = undefined;

	_renderTable(
		table: HTMLTableElement,
		getOptions: () => Config,
		getData: () => {
			id: string;
		}[],
		updateData: (
			data: {
				id: string;
			}[],
		) => void,
	) {
		const data = getData();
		if (data.length) {
			if (this.dTable) {
				this.dTable.destroy();
			}
			const options = getOptions();
			const dt = new DataTable(table, {
				data,
				...options,
				initComplete: () => {
					if (
						table.parentElement &&
						table.parentElement.parentElement &&
						table.parentElement.parentElement.parentElement
					) {
						table.parentElement.parentElement.parentElement.classList.add('table-simulation-use-cases');
					}
				},
			});

			this.dTable = dt;

			dt.select().on('select', (a: any, dataSelect: string[]) => {
				this.dtSelection = dt.rows({ selected: true }).data().length;
			});

			dt.select().on('deselect', (a: any, dataSelect: string[]) => {
				this.dtSelection = dt.rows({ selected: true }).data().length;
			});

			table.querySelectorAll('.open-button').forEach((el) => {
				el.addEventListener('click', () => {
					const rowData: { id: string } = dt.row(el.closest('tr')).data();
					this.currentUseCase = undefined;
					setTimeout(() => {
						if (this.simulation) {
							this.currentUseCase = this.simulation.use_cases.find(
								(d: UseCaseSimulation) => d.id === rowData.id,
							);
							console.log(this.simulation, rowData.id, this.currentUseCase);
							if (this.currentUseCase) {
								this.displayModal = true;
							}
						}
					}, 50);
				});
				tippy(el, {
					content: 'See details',
					appendTo: 'parent',
					arrow: false,
					offset: [0, -5],
					placement: 'top',
					zIndex: 100,
					duration: 10,
				});
			});

			table.querySelectorAll('.delete-button').forEach((el) => {
				el.addEventListener('click', () => {
					this.storeCurrentOrder();
					const row = dt.row(el.closest('tr'));
					const rowData: { id: string } = row.data();
					if (this.simulation) {
						const useCase = this.simulation.use_cases.find((d: UseCaseSimulation) => d.id === rowData.id);
						if (useCase) {
							this.confirmationService.confirm({
								header: 'Delete the use case?',
								message: 'This will delete the use case from the simulations.',
								icon: 'pi pi-exclamation-triangle',
								accept: () => {
									this.deleteUseCase(useCase).then(() => {
										row.remove().draw();
										const data = getData().filter((d) => d.id !== useCase.id);
										updateData(data);
									});
								},
							});
						}
					}
				});
				tippy(el, {
					content: 'Delete',
					appendTo: 'parent',
					arrow: false,
					offset: [0, -5],
					placement: 'top',
					zIndex: 100,
					duration: 10,
				});
			});

			table.querySelectorAll('.star-icon').forEach((el) => {
				el.addEventListener('click', async () => {
					this.storeCurrentOrder();
					const row = dt.row(el.parentElement?.parentElement);
					if (row && row.data() && this.simulation) {
						const useCaseData: UseCaseSimulation = row.data();
						let useCase: UseCaseSimulation | undefined = undefined;
						this.simulation.use_cases = this.simulation.use_cases.map((d) => {
							if (d.id === useCaseData.id) {
								d.valid = !d.valid;
								useCase = d;
							}
							return d;
						});
						if (useCase) {
							console.log('PROMPT STUDIO - update star');
							this.loadTable();
							this.restoreOrder();
							await this.existSimulation().then(async (existSimulation) => {
								if (!existSimulation) {
									await this._saveSimulation(existSimulation);
								}
								this.use_cases_saved.push((useCase as any).id as string);
								this.simulationService.saveUseCase(useCase as any);
								this.simulationService.updateSimulationsSubject.next(uuid());
							});
						}
						this.restoreOrder();
					}
				});
			});

			table.querySelectorAll('.star-icon.text-primary').forEach((el) => {
				tippy(el, {
					content: 'Hackett Taxonomy',
					appendTo: 'parent',
					arrow: false,
					offset: [0, -5],
					placement: 'top',
					zIndex: 100,
					duration: 10,
				});
			});

			table.querySelectorAll('.star-icon.text-green-300').forEach((el) => {
				tippy(el, {
					content: 'Hackett Industry',
					appendTo: 'parent',
					arrow: false,
					offset: [0, -5],
					placement: 'top',
					zIndex: 100,
					duration: 10,
				});
			});
		}
	}

	async deleteUseCase(useCase: UseCaseSimulation) {
		if (this.useCasesSaved) {
			await this.simulationService.deleteUseCase(useCase);
		}
		if (this.simulation) {
			this.simulation.use_cases = this.simulation.use_cases.filter((u) => u.id !== useCase.id);
		}
		this.dtSelection = 0;
		this.simulationService.updateSimulationsSubject.next(uuid());
	}

	async deleteUseCases() {
		if (this.dTable) {
			const rows = this.dTable.rows({ selected: true });
			if (rows.length && rows.data().length) {
				this.confirmationService.confirm({
					header: `Delete the ${this.dtSelection} use cases?`,
					message: 'This will delete the use cases from the simulations.',
					icon: 'pi pi-exclamation-triangle',
					accept: () => {
						const ids: string[] = [];
						const updates = [...Array(rows.data().length)]
							.map((_, i) => {
								const rowData: UseCaseSimulation = rows.data()[i];
								if (this.simulation) {
									ids.push(rowData.id as string);
									return this.simulation.use_cases.find(
										(d: UseCaseSimulation) => d.id === rowData.id,
									);
								}
								return undefined;
							})
							.filter((d) => !!d)
							.map((d) => {
								if (this.useCasesSaved) {
									return this.simulationService.deleteUseCase(d as UseCaseSimulation);
								} else {
									return;
								}
							});

						Promise.all(updates).then(() => {
							rows.remove().draw();
							if (this.simulation) {
								this.simulation.use_cases = this.simulation.use_cases.filter(
									(d) => !ids.includes(d.id as string),
								);
							}
							this.dtSelection = 0;
							this.simulationService.updateSimulationsSubject.next(uuid());
						});
					},
				});
			}
		}
	}

	updateTaxonomy() {
		const getParents = (node: TreeNode): string => {
			if (node.parent !== undefined) {
				return getParents(node.parent);
			}
			return node.key as string;
		};

		const t = setTimeout(() => {
			const taxonomyWithParent = this.taxonomyTreeSelections
				.filter((n) => n.parent !== undefined)
				.map((n) => getParents(n));
			const taxonomyList = this.useCasesService.functionsTree.map((t) => t.key as string);
			const taxonomyWithoutParent = this.taxonomyTreeSelections
				.filter((n) => n.parent === undefined)
				.filter((n) => taxonomyList.includes(n.key as string))
				.map((n) => n.key as string);

			this.simulationService.taxonomyPromptStudio = Array.from(
				new Set([...taxonomyWithParent, ...taxonomyWithoutParent]),
			);

			clearTimeout(t);
		}, 200);
	}
}
